import React from 'react';

import { Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

interface DescriptionProps {
  job?: Job;
}

const useStyles = makeStyles((theme) => ({
  description: {
    '& > p': {
      margin: 0,
    },
  },
}));

const Description = ({ job }: DescriptionProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Typography component="h6" paragraph variant="h6">
          Job description
        </Typography>
        <Typography
          className={classes.description}
          component="div"
          dangerouslySetInnerHTML={{
            __html: job?.striive_job?.content || job?.description || '',
          }}
          paragraph
          variant="body2"
        />
      </Box>
    </Paper>
  );
};

export default Description;
