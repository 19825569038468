import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { TALENT_CATEGORIES } from 'graphql/talents';
import { useCurrentUser } from 'hooks/auth';
import React from 'react';
import RateSelector from 'screens/company-user/workforce/search-filter/rate-selector';
import { DefaultSkillsControl } from 'screens/company-user/workforce/search-filter/skills-selector/default-skills-control';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  GetCountiresListDocument,
  TalentCompanyPoolingStatusEnum,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import Accordion from '@libs/ui/components/accordion/Accordion';
import { ConnectedCheckbox } from '@libs/ui/components/form/checkbox';
import { GraphConnectedMultipleSelect } from '@libs/ui/components/form/multiple-select';
import { ConnectedGraphSelect } from '@libs/ui/components/form/select';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import Typography from '@libs/ui/components/typography';

import { FilterForm } from '../types';

interface FilterProps {
  toggleDrawer: VoidFunction;
  showOnlyTalentView?: boolean;
  openCount?: number;
  externalCount?: number;
}

const useStyles = makeStyles(() => ({
  betaChip: {
    position: 'absolute',
    right: 4,
    bottom: 9,
    background: '#ED6C02',
    color: 'white',
  },
  root: {},
}));

const Filter = ({ toggleDrawer, openCount, externalCount }: FilterProps) => {
  const classes = useStyles();
  const { getData } = useCurrentUser();
  const connections = getData()?.data?.currentTalent?.company_pool_connections;

  return (
    <Paper className={classes.root} elevation={0}>
      <Box px={4} py={4}>
        <Hidden lgUp>
          <Box pb={4}>
            <Grid alignItems="center" justifyContent="space-between" container>
              <Grid item>
                <Typography>Search Filter</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={toggleDrawer} size="small">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Hidden>
        <Accordion
          defaultExpanded
          summary={
            <Typography variant="overline">SELECT JOB SOURCE</Typography>
          }
          details={
            <Box flexGrow={1} mb={2} position="relative">
              {!!connections?.length &&
                connections.map((i) => {
                  const companyId = i?.company?.id;

                  if (
                    !companyId ||
                    i.status !== TalentCompanyPoolingStatusEnum.Approved
                  ) {
                    return null;
                  }

                  const isOp = i?.company?.name === 'OpenTalent';
                  const name = isOp
                    ? modelPath<FilterForm>((m) => m.jobSourceInternal)
                    : modelPath<FilterForm>((m) => m.companies[companyId]);

                  return (
                    <Box>
                      <ConnectedCheckbox
                        name={name}
                        label={
                          isOp
                            ? `OpenTalent (${openCount || 0})`
                            : i?.company?.name
                        }
                        color="info"
                      />
                    </Box>
                  );
                })}
            </Box>
          }
        />

        <Accordion
          defaultExpanded
          summary={
            <Typography variant="overline">FILTER BY CATEGORY</Typography>
          }
          details={
            <Box flexGrow={1} mb={2}>
              <GraphConnectedMultipleSelect
                name="categories"
                query={TALENT_CATEGORIES}
                dataPath="talentCategories"
                dataMap={{ text: 'name', value: 'id' }}
                chipProps={{
                  color: 'tertiary',
                  size: 'small',
                }}
                inputProps={{
                  variant: 'filled',
                  label: 'Pick from list',
                  placeholder: '',
                  margin: 'dense',
                  fullWidth: true,
                }}
              />
            </Box>
          }
        />
        <Accordion
          defaultExpanded
          summary={
            <Typography variant="overline">FILTER BY COUNTRY</Typography>
          }
          details={
            <Box flexGrow={1} mb={2}>
              <ConnectedGraphSelect
                query={GetCountiresListDocument}
                dataPath="getCountriesList"
                hideNoneValue
                dataMap={{ text: 'name', value: 'name' }}
                formControlProps={{ size: 'small' }}
                fullWidth
                queryOptions={{ variables: { is_europe: true } }}
                label="Country"
                name="country"
                variant="filled"
              />
            </Box>
          }
        />
        <Accordion
          defaultExpanded
          summary={<Typography variant="overline">SEARCH</Typography>}
          details={
            <Box flexGrow={1} mb={2}>
              <ConnectedTextField
                label="Search by Job title, Keywords..."
                name="search"
                variant="filled"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon width="14" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          }
        />
        <Accordion
          defaultExpanded
          summary={<Typography variant="overline">FILTER BY SKILLS</Typography>}
          details={
            <Box flexGrow={1} mb={2}>
              <DefaultSkillsControl name="skills" />
            </Box>
          }
        />
        <Accordion
          defaultExpanded
          summary={<Typography variant="overline">HOURLY RATES</Typography>}
          details={
            <Box flexGrow={1} mb={2} px={2}>
              <RateSelector
                minValue={{ name: 'rate_min' }}
                maxValue={{ name: 'rate_max' }}
              />
            </Box>
          }
        />
      </Box>
    </Paper>
  );
};

export default Filter;
