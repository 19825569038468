import {
  intervalToDuration,
  isPast,
  parseISO,
  differenceInDays,
} from 'date-fns';

export const useIntervalTimer = ({
  start,
  end,
  currentTime,
}: {
  start: string;
  end: string;
  currentTime: Date;
}) => {
  if (!start || !end) {
    return {};
  }

  const startFrom = parseISO(
    isPast(parseISO(start)) ? currentTime.toISOString() : start,
  );
  const endFrom = parseISO(
    isPast(parseISO(end)) ? currentTime.toISOString() : end,
  );
  const daysDiff = differenceInDays(endFrom, startFrom);

  return {
    daysDiff,
    duration: intervalToDuration({
      start: startFrom,
      end: endFrom,
    }),
  };
};
