import React, { useMemo } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Paper, Grid, Tooltip, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import { useMediaQueries } from '@libs/helpers/hooks/media-queries';
import Button from '@libs/ui/components/button';
import {
  CampaignStatus,
  getJobFieldsForCompany,
  getStriiveJobFields,
} from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import { CAMPAIGN_LABELS } from './consts';

interface GeneralInfoProps {
  job?: Job;
  campaignStatus: CampaignStatus;
  setWithDetails?: VoidFunction;
  withDetails?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      'body &': {
        paddingTop: theme.spacing(16),
      },
    },
  },
  label: {
    background: ({ status }: { status: CampaignStatus }) =>
    ({
      [CampaignStatus.Started]: theme.palette.success.light,
      [CampaignStatus.Finished]: theme.palette.other.lightBlue,
      [CampaignStatus.NotStarted]: theme.palette.error.light,
      [CampaignStatus.Archived]: theme.palette.grey[400],
      [CampaignStatus.Draft]: theme.palette.grey[400],
    }[status]),
    padding: `0px ${theme.spacing(2)}`,
    position: 'absolute',
    borderRadius: theme.spacing(1),
    right: theme.spacing(-1),
    top: theme.spacing(6),
    lineHeight: '26px',
  },
  infoRow: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',

    '& > div': {
      paddingRight: 20,
      paddingBottom: 4,

      width: '33%',

      [theme.breakpoints.down('md')]: {
        'body &': {
          minWidth: '50% !important',
        },
      },
    },
  },
}));

const GeneralInfo = ({
  campaignStatus,
  job,
  setWithDetails,
  withDetails = true,
}: GeneralInfoProps) => {
  const classes = useStyles({ status: campaignStatus });
  const { isSM } = useMediaQueries();
  const isStriiveJob = !!job?.striive_job;
  const DATA = useMemo(
    () =>
      job?.striive_job
        ? getStriiveJobFields(job)
        : getJobFieldsForCompany(job as Job),
    [job],
  );

  return (
    <Paper elevation={0}>
      <Box className={classes.root} p={4}>
        <Typography variant="h6" paragraph>
          About
        </Typography>
        <Typography className={classes.label} variant="subtitle2">
          {CAMPAIGN_LABELS[campaignStatus]}
        </Typography>
        {!isStriiveJob && (
          <Grid spacing={2} component={Box} pt={isSM ? 2 : 5} container>
            <Grid item>
              <Typography style={{ fontWeight: 500 }} variant="subtitle2">
                Category:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{job?.category?.name}</Typography>
            </Grid>
          </Grid>
        )}
        <div className={classes.infoRow}>
          {DATA.map(({ label, value, hint }) => (
            <div key={`${label}-${value}`}>
              <Typography
                style={{ fontWeight: 500 }}
                component="b"
                variant="subtitle2"
              >
                {label}
              </Typography>{' '}
              <Typography component="span" variant="body2">
                {value}
              </Typography>
              {hint && (
                <Typography
                  component="span"
                  style={{ verticalAlign: 'middle' }}
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={hint}
                    placement="right"
                  >
                    <InfoIcon
                      style={{ margin: '0 auto -4px' }}
                      fontSize="small"
                      color="disabled"
                    />
                  </Tooltip>
                </Typography>
              )}
            </div>
          ))}
        </div>
      </Box>

      {setWithDetails && (
        <Box px={4} pb={4} display="flex" justifyContent="end">
          <Button
            color="tertiary"
            endIcon={withDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setWithDetails?.()}
            variant="text"
          >
            {`Show ${withDetails ? 'less' : 'more'}`}
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default GeneralInfo;
