import { useCurrentTime } from 'hooks/common/useCurrentTime';
import React from 'react';
import { mapStriiveJobFields } from 'screens/talent/job-apply/job-info/utils';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import { getJobName } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import DataGrid from './DataGrid';
import JobTimer from './JobTimer';
import { mapJobToDataGridItems } from './utils';

interface JobInfoProps {
  job: Required<Job>;
  applyButton: React.ReactNode;
  isExpand: boolean;
  setIsExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(4),

    '&:before': {
      display: 'none',
    },
  },
  title: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(4),
  },
  titleDataGrid: {
    paddingBottom: theme.spacing(2),
  },
  summaryRoot: {
    alignItems: 'flex-start',
    padding: `${theme.spacing(4)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
  },
  summaryContent: {
    margin: '0 -36px 0 0 !important',
  },
  detailsRoot: {
    padding: `0 ${theme.spacing(4)}`,
  },
  expandIcon: {},
  chip: {
    maxWidth: 240,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  toggler: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    color: 'black !important',

    '& *': {
      color: 'black !important',
    },
    '& > p': {
      margin: 0,
    },
  },
}));

const JobInfo = React.forwardRef<HTMLDivElement, JobInfoProps>(
  ({ applyButton, job, isExpand, setIsExpand }, ref) => {
    const classes = useStyles();
    const currentTime = useCurrentTime();
    const toggle = () => setIsExpand(!isExpand);
    const isStriiveJob = !!job?.striive_job;
    const jobName = job ? getJobName(job) : '';

    return (
      <Paper elevation={0} square classes={{ root: classes.root }} ref={ref}>
        <Box padding={4}>
          <Box className={classes.titleDataGrid} width="100%">
            <Box className={classes.titleRow}>
              <Typography variant="h6">Job details</Typography>
              <JobTimer currentTime={currentTime} job={job} />
            </Box>
            <Box pt={7} pb={6}>
              <Typography>{jobName}</Typography>
            </Box>

            <DataGrid
              items={
                isStriiveJob
                  ? mapStriiveJobFields(job)
                  : mapJobToDataGridItems(job)
              }
            />

            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Typography
                className={classes.toggler}
                component="span"
                color="tertiary"
                variant="body2"
                onClick={toggle}
              >
                {isExpand ? 'Show less' : 'Find out more'}
                {isExpand ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Typography>
            </Box>
          </Box>
          <Collapse in={isExpand}>
            <Box pb={4} width="100%">
              <div
                className={classes.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: job?.description || '' }}
              />
            </Box>
          </Collapse>

          <Box>{applyButton}</Box>
        </Box>
      </Paper>
    );
  },
);
export default JobInfo;
