import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { usePushWithQuery, useSearchParams } from 'hooks/routing';
import { useTalentApprovedPoolConnections } from 'hooks/talents';
import { useIsPaidTalentAccount } from 'hooks/talents/useTalentAccountType';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Job,
  Query,
  QueryCurrentTalentJobBoardSearchArgs,
} from '@libs/graphql-types';

import { GET_JOBS } from './queries';
import { FilterForm } from './types';
import {
  mapFormToQuery,
  mapQueryToInitialValues,
  mapQueryToVariables,
} from './utils';

export type LoadVariablesType = {
  variables: QueryCurrentTalentJobBoardSearchArgs;
};

export const useQueryParamsToLoadVariables = (): LoadVariablesType => {
  const params = useSearchParams();
  const isPaidTalentAccount = useIsPaidTalentAccount();
  const approvedConnections = useTalentApprovedPoolConnections();
  const variables = useMemo(() => {
    return {
      variables: mapQueryToVariables(params, {
        isPaidTalentAccount,
        connections: approvedConnections,
      }),
    };
  }, [approvedConnections, isPaidTalentAccount, params]);

  return variables;
};

export const useJobList = () => {
  const { variables } = useQueryParamsToLoadVariables();
  const [loadJobs, { data, loading }] = useLazyQuery<Query>(GET_JOBS, {
    variables,
    fetchPolicy: 'network-only',
  });

  const loadWithOptions = useCallback(() => {
    console.log('LOG: variables', variables);
    loadJobs({ variables });
  }, [loadJobs, variables]);

  useEffect(() => {
    loadWithOptions();
  }, [loadWithOptions, variables]);

  const { currentPage = 1, lastPage = 1 } =
    data?.currentTalentJobBoardSearch?.paginatorInfo || {};

  return {
    jobs: data?.currentTalentJobBoardSearch?.data || [],
    loadingJobList: loading,
    currentPage,
    lastPage,
    loadJobs: loadWithOptions,
  };
};

export const useJobModals = () => {
  const [inviteJob, setInviteJob] = useState<Required<Job>>();
  const [applyJob, setApplyJob] = useState<Required<Job>>();

  const handleClose = useCallback(() => {
    setInviteJob(undefined);
    setApplyJob(undefined);
  }, []);

  return {
    inviteJob,
    applyJob,
    handleClose,
    onJobApply: setApplyJob,
    onInvite: setInviteJob,
  };
};

export const useAutoSaveHandler = () => {
  const push = usePushWithQuery();
  return useCallback(
    (newValues: FilterForm) => {
      const mapped = mapFormToQuery(newValues);

      push({ query: { ...mapped } });
    },
    [push],
  );
};

export const useCheckPagination = ({
  lastPage,
  currentPage,
}: {
  lastPage: number;
  currentPage: number;
}) => {
  const push = usePushWithQuery();

  useEffect(() => {
    if (currentPage > lastPage) {
      push({ query: { page: 1 } });
    }
  });
};

export const useInitialValues = () => {
  const params = useSearchParams();
  const isPaidTalentAccount = useIsPaidTalentAccount();
  const approvedConnections = useTalentApprovedPoolConnections();

  return mapQueryToInitialValues(params, {
    isPaidTalentAccount,
    connections: approvedConnections,
  });
};

export const usePaginationChange = () => {
  const { setFieldValue } = useFormikContext();

  const onPaginationChange = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => {
      setFieldValue('page', page);
    },
    [setFieldValue],
  );

  return onPaginationChange;
};
