import { gql } from '@apollo/client';

export const GET_JOBS_COUNT_TALENT = gql`
  query GetJobTabsCountTalent(
    $skills: [ID]
    $categories: [ID]
    $rate_min: Float
    $rate_max: Float
    $search: String
    $order_by: [QueryCurrentTalentJobBoardSearchOrderByOrderByClause!]
    $company_id: ID
  ) {
    open: currentTalentJobBoardSearch(
      skills: $skills
      is_active: true
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      company_id: $company_id
      job_source_type: [INTERNAL, STRIIVE]
    ) {
      paginatorInfo {
        total
      }
    }
    external: currentTalentJobBoardSearch(
      skills: $skills
      is_active: true
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      company_id: $company_id
      job_source_type: [STRIIVE]
    ) {
      paginatorInfo {
        total
      }
    }
    applications: currentTalentJobBoardSearch(
      is_applied: true
      skills: $skills
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      company_id: $company_id
    ) {
      paginatorInfo {
        total
      }
    }
    closed: currentTalentJobBoardSearch(
      skills: $skills
      is_active: false
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      company_id: $company_id
    ) {
      paginatorInfo {
        total
      }
    }
    recommended: currentTalentJobRecommedations {
      paginatorInfo {
        total
      }
    }

    saved: currentTalentJobBoardSearch(order_by: $order_by, is_saved: true) {
      paginatorInfo {
        total
      }
    }
    savedExternal: currentTalentExternalJobBoardSearch(is_saved: true) {
      paginatorInfo {
        total
      }
    }
  }
`;

export const GET_JOBS_COUNT_MATCHER = gql`
  query GetJobTabsCountMatcher(
    $skills: [ID]
    $categories: [ID]
    $rate_min: Float
    $rate_max: Float
    $search: String
    $order_by: [QueryCurrentTalentJobBoardSearchOrderByOrderByClause!]
    $company_id: ID
  ) {
    direct: currentTalentJobBoardSearch(is_active: true) {
      paginatorInfo {
        total
      }
    }

    external: currentTalentExternalJobBoardSearch {
      paginatorInfo {
        total
      }
    }

    open: currentTalentJobBoardSearch(
      skills: $skills
      is_active: true
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      job_source_type: [INTERNAL, STRIIVE]
      job_matcher_jobs_filter: ALL_AVAILABLE_FOR_MATCHERS_JOBS
    ) {
      paginatorInfo {
        total
      }
    }

    applications: currentTalentJobBoardSearch(
      skills: $skills
      is_active: true
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      job_matcher_jobs_filter: IS_APPLIED_OR_ASSIGNED
      job_source_type: [INTERNAL, STRIIVE]
      is_assigned_as_matcher: true
      is_applied_as_matcher: true
    ) {
      paginatorInfo {
        total
      }
    }
    closed: currentTalentJobBoardSearch(
      skills: $skills
      is_active: false
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      company_id: $company_id
    ) {
      paginatorInfo {
        total
      }
    }
  }
`;
