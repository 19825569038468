import { gql } from '@apollo/client';
import JOB_FRAGMENT from 'graphql/fragments/talent/jobBoardJobFragment';

export const GET_JOBS_COUNT = gql`
  query GetJobsCountForFilter {
    direct: currentTalentJobBoardSearch(
      is_active: true
      job_source_type: [INTERNAL, STRIIVE]
    ) {
      paginatorInfo {
        total
      }
    }
    external: currentTalentJobBoardSearch(
      is_active: true
      job_source_type: [STRIIVE]
    ) {
      paginatorInfo {
        total
      }
    }
  }
`;

export const GET_JOBS = gql`
  ${JOB_FRAGMENT}
  query GetJobs(
    $skills: [ID]
    $categories: [ID]
    $rate_min: Float
    $rate_max: Float
    $search: String
    $order_by: [QueryCurrentTalentJobBoardSearchOrderByOrderByClause!]
    $is_saved: Boolean
    $first: Int = 20
    $page: Int
    $job_source_type: [JobSourceType]
    $is_active: Boolean = true
    $communities_filter: JobCommunitiesFilterInput
    $country: String
  ) {
    currentTalentJobBoardSearch(
      skills: $skills
      country: $country
      communities_filter: $communities_filter
      job_source_type: $job_source_type
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      is_saved: $is_saved
      first: $first
      page: $page
      is_active: $is_active
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        ...JobBoardJobFragment
      }
    }
  }
`;

export const SAVE_JOB = gql`
  mutation SaveJobToFavorites($job_id: ID!) {
    saveJobToFavorites(job_id: $job_id)
  }
`;

export const DELETE_JOB = gql`
  mutation DeleteJobFromFavorites($job_id: ID!) {
    deleteJobFromFavorites(job_id: $job_id)
  }
`;

export const SAVE_EXTERNAL_JOB = gql`
  mutation SaveJobToFavorites($external_job_id: ID!) {
    saveExternalJobToFavorites(external_job_id: $external_job_id)
  }
`;

export const DELETE_EXTERNAL_JOB = gql`
  mutation DeleteJobFromFavorites($external_job_id: ID!) {
    deleteExternalJobFromFavorites(external_job_id: $external_job_id)
  }
`;

export const WITHDRAW_JOB = gql`
  mutation CancelApplicationForJob($job_id: ID!, $reason: String) {
    cancelApplicationForJob(job_id: $job_id, reason: $reason)
  }
`;
