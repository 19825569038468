import { useCurrentUser } from 'hooks/auth';
import { useMemo } from 'react';

import { TalentCompanyPoolConnection } from '@libs/graphql-types';

export const useTalentPoolConnections = () => {
  const { getData } = useCurrentUser();
  const connections =
    getData()?.data?.currentTalent?.company_pool_connections || [];

  return connections;
};

export const useTalentApprovedPoolConnections = () => {
  const connections = useTalentPoolConnections();
  const approved = useMemo(() => {
    return connections.filter(
      (i) => i?.status === 'APPROVED',
    ) as TalentCompanyPoolConnection[];
  }, [connections]);

  return approved;
};
